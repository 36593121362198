<template>
  <div>
    <FormGroup
      v-model="$v.form.firstName.$model.value"
      @input="$emit('fieldChanged', form.firstName)"
      :field="$v.form.firstName"
      :isValidationRun="isValidationRun"
      :disabled="formDisabled"
    />

    <FormGroup
      v-model="$v.form.lastName.$model.value"
      @input="$emit('fieldChanged', form.lastName)"
      :field="$v.form.lastName"
      :isValidationRun="isValidationRun"
      :disabled="formDisabled"
    />

    <FormGroup
      v-model="form.companyName.value"
      @input="$emit('fieldChanged', form.companyName)"
      :field="form.companyName"
      :isValidationRun="isValidationRun"
      :disabled="formDisabled"
    />

    <FormGroup
      v-model="$v.form.contact1.$model.value"
      @input="$emit('fieldChanged', form.contact1)"
      :field="$v.form.contact1"
      :isValidationRun="isValidationRun"
      :disabled="formDisabled"
    />

    <FormGroup
      v-model="$v.form.contact2.$model.value"
      @input="$emit('fieldChanged', form.contact2)"
      :field="$v.form.contact2"
      :isValidationRun="isValidationRun"
      :disabled="formDisabled"
    />

    <FormGroup
      v-model="$v.form.city.$model.value"
      @input="$emit('fieldChanged', form.city)"
      :field="$v.form.city"
      :isValidationRun="isValidationRun"
      :disabled="formDisabled"
    />

    <FormSelect
      v-model="$v.form.state.$model.value"
      @input="$emit('fieldChanged', form.state)"
      :field="$v.form.state"
      :isValidationRun="isValidationRun"
      :disabled="formDisabled"
    />

    <FormGroup
      v-model="$v.form.postalCode.$model.value"
      @input="$emit('fieldChanged', form.postalCode)"
      :field="$v.form.postalCode"
      :isValidationRun="isValidationRun"
      :disabled="formDisabled"
    />

    <FormGroup
      v-model="$v.form.phone.$model.value"
      @input="$emit('fieldChanged', form.phone)"
      :field="$v.form.phone"
      :isValidationRun="isValidationRun"
      :disabled="formDisabled"
    />

    <p>* indicates required field</p>

    <slot name='bottom-slot' />
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup'
import FormSelect from '@/components/FormSelect'

export default {
  components: {
    FormGroup,
    FormSelect
  },
  props: ['isValidationRun', '$v', 'form', 'formDisabled']
}
</script>
