import { required, maxLength, numeric, minLength } from 'vuelidate/lib/validators'

import usStates from '@/data/us-states'
import canadaStates from '@/data/canada-states'
import settings from '@/settings'
import FormValidationMixin from '@/mixins/FormValidationMixin'

export default {
  mixins: [FormValidationMixin],
  methods: {
    setAddressFormToDefault () {
      Object.values(this.form).forEach((field) => {
        if (typeof field.defaultValue === 'object' && field.defaultValue !== null) {
          field.value = { ...field.defaultValue }
        } else {
          field.value = field.defaultValue
        }
      })
    },
    isTwoAddressesEqualForValidation (firstAddress, secondAddress) {
      const parseAddressForValidation = (addressObject) => {
        const fieldsForValidation = {
          line1: addressObject.line1,
          line2: addressObject.line2,
          postalCode: addressObject.postalCode,
          region: addressObject.region?.isocode,
          town: addressObject.town
        }
        for (const key in fieldsForValidation) {
          if (!fieldsForValidation[key]) delete fieldsForValidation[key]
        }
        return { ...fieldsForValidation }
      }

      return this.isEqualObjects(
        parseAddressForValidation(firstAddress),
        parseAddressForValidation(secondAddress)
      )
    },
    prepareFields () {
      switch (settings.app.country) {
        case 'CA':
          this.form.country.value = { isocode: 'CA', name: 'Canada' }
          this.form.country.defaultValue = { isocode: 'CA', name: 'Canada' }
          this.form.state.options = canadaStates
          this.form.state.defaultValue = canadaStates
          break
      }
    }
  },
  created () {
    this.prepareFields()
  },
  validations () {
    return {
      form: {
        firstName: {
          value: {
            required,
            maxLength: maxLength(35),
            server: this.server
          }
        },
        lastName: {
          value: {
            required,
            maxLength: maxLength(35),
            server: this.server
          }
        },
        contact1: {
          value: {
            required,
            maxLength: maxLength(35),
            server: this.server
          }
        },
        contact2: {
          value: {
            maxLength: maxLength(35),
            server: this.server
          }
        },
        city: {
          value: {
            required,
            maxLength: maxLength(35),
            server: this.server
          }
        },
        state: {
          value: {
            required,
            server: this.server
          }
        },
        postalCode: {
          value: {
            required,
            maxLength: maxLength(10),
            server: this.server
          }
        },
        phone: {
          value: {
            required,
            numeric,
            maxLength: maxLength(10),
            minLength: minLength(10),
            server: this.server
          }
        }
      }
    }
  },
  data () {
    return {
      isValidationRun: false,
      form: {
        id: {
          title: 'id',
          value: '',
          defaultValue: ''
        },
        defaultAddress: {
          title: 'defaultAddress',
          value: false,
          defaultValue: false
        },
        firstName: {
          placeholder: 'First Name *',
          value: '',
          defaultValue: '',
          title: 'firstName',
          type: 'text',
          variant: 'shadow',
          maxLength: '35',
          inline: true,
          errors: {
            required: 'Please enter a First Name',
            minLength: 'Please provide an alternate name with minimum length of 2 characters'
          },
          disabled: false
        },
        lastName: {
          placeholder: 'Last Name *',
          value: '',
          defaultValue: '',
          title: 'lastName',
          type: 'text',
          variant: 'shadow',
          maxLength: '35',
          inline: true,
          errors: {
            required: 'Please enter a Last Name',
            minLength: 'Please provide an alternate name with minimum length of 2 characters'
          },
          disabled: false
        },
        companyName: {
          placeholder: 'Company Name',
          value: '',
          defaultValue: '',
          title: 'companyName',
          variant: 'shadow',
          type: 'text',
          maxLength: '35',
          disabled: false
        },
        contact1: {
          placeholder: 'Street Address Line 1 *',
          value: '',
          defaultValue: '',
          title: 'line1',
          type: 'text',
          variant: 'shadow',
          maxLength: '35',
          errors: {
            required: 'Please enter a Street Address Line 1',
            minLength: 'Please provide an alternate address with minimum length of 3 characters'
          },
          disabled: false
        },
        contact2: {
          placeholder: 'Street Address Line 2',
          value: '',
          defaultValue: '',
          title: 'line2',
          variant: 'shadow',
          type: 'text',
          maxLength: '35',
          errors: {},
          disabled: false
        },
        city: {
          placeholder: 'City *',
          value: '',
          defaultValue: '',
          title: 'town',
          type: 'text',
          maxLength: '35',
          variant: 'shadow',
          inline: true,
          errors: {
            required: 'Please enter a City',
            minLength: 'Please provide an alternate city with minimum length of 3 characters'
          },
          disabled: false
        },
        state: {
          placeholder: 'State/Territory *',
          options: usStates,
          value: {},
          defaultValue: {},
          titleField: 'name',
          valueField: 'isocode',
          title: 'region',
          type: 'text',
          label: 'name',
          variant: 'shadow',
          inline: true,
          errors: {
            required: 'Please enter a State/Territory'
          },
          disabled: false
        },
        postalCode: {
          placeholder: 'ZIP/Postal Code *',
          value: '',
          defaultValue: '',
          title: 'postalCode',
          type: 'text',
          maxLength: '10',
          variant: 'shadow',
          inline: true,
          errors: {
            required: 'Please enter a ZIP / Postal Code'
          },
          disabled: false
        },
        country: {
          title: 'country',
          value: {
            isocode: 'US',
            name: 'United States'
          },
          defaultValue: {
            isocode: 'US',
            name: 'United States'
          },
          disabled: false
        },
        phone: {
          inputType: 'the-mask',
          placeholder: 'Phone Number *',
          value: '',
          defaultValue: '',
          title: 'phone',
          type: 'tel',
          mask: '###-###-####',
          inline: true,
          variant: 'shadow',
          errors: {
            required: 'Please enter a 10-digit Phone Number',
            numeric: 'Please enter a 10-digit Phone Number',
            minLength: 'Please enter a 10-digit Phone Number'
          },
          disabled: false
        }
      }
    }
  }
}
