<template>
  <div class="cart-products-list product-line">
    <table class="cart-products-list__table" v-if="!isMobile">
      <thead>
        <tr class="product-line__row">
          <th
            v-for="(title, n) in tableHeaderTitles"
            :key="title"
            v-html="title"
            class="cart-products-list__header-title"
            :class="[
              n === 0 ? 'cart-products-list__header-title--info' : '',
              title === 'Add To Cart' ? 'text-nowrap' : '',
              title === 'Price' ? 'cart-products-list__header-title--price' : '',
              title === 'Quantity' ? 'cart-products-list__header-title--center' : ''
            ]"
            colspan="1"
          ></th>
        </tr>
      </thead>
      <tbody>
        <slot name="cart-entry"></slot>
      </tbody>
    </table>
    <div class="cart-products-list__mobile-table" v-else>
      <div class="cart-products-list__mobile-header"> {{ `${productsQty} ${productsQty > 1 ? 'items' : 'item'}` }}</div>
      <slot name="cart-entry"></slot>
    </div>
    <slot name="loader"></slot>
    <div>
    </div>
  </div>
</template>

<script>
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'

export default {
  name: 'CartProductsList',
  mixins: [ScreenWidthMixin],
  props: ['isListEditable', 'productsQty', 'isAddToCartVisible'],
  computed: {
    tableHeaderTitles () {
      return this.isListEditable
        ? [
          'Product Information',
          'Quantity',
          'Price',
          this.isAddToCartVisible ? 'Add To Cart' : '',
          'Remove'
        ].filter(el => el) : [
          'Product Information',
          'Quantity',
          'Price'
        ]
    }
  }
}
</script>
