<!-- @TODO cleanup -->
<template>
  <tr class="product-line__row product-line__item" v-if="!isMobile">
    <td class="product-line__data" :data-th-title="'Quantity'">
      <div
        class="cart-products-list__product-wrapper product-line__subitem-wrapper"
      >
        <div class="cart-products-list__img-wrapper">
          <a
            v-if="isImageClickable"
            @click.prevent="handleProductLinkClick(productData.link)"
            :href="productData.link"
          >
            <img
              :src="productData.image.previewSrc || '/img-placeholder.svg'"
              :alt="productData.image.title"
              class="cart-products-list__img"
              loading="lazy"
            />
          </a>
          <img
            v-else
            :src="productData.image.previewSrc || '/img-placeholder.svg'"
            :alt="productData.image.title"
            class="cart-products-list__img"
            loading="lazy"
          />
        </div>
        <div class="cart-products-list__description">
          <a
            @click.prevent="handleProductLinkClick(productData.link)"
            v-html="productData.title"
            :href="productData.link"
            class="product-line__title"
          ></a>
          <p class="product-line__sku">
            Catalog ID: {{ productData.catalogId }}
          </p>
          <div class="cart-product__actions">
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
    </td>

    <td
      class="product-line__data product-line__data-qty"
      :data-th-title="'Quantity'"
    >
      <div class="cart-products-list__qty">
        <div class="product-line__data-qty-text" v-if="productData.minOrderQty > 1">
          Incremental Order Quantity: {{ productData.minOrderQty }}
          <i
            class="icon-question-circle"
            v-tooltip="`This item has an incremental order quantity of ${productData.minOrderQty}; <br/> typing in a quantity value may result in rounding`"
          />
        </div>
        <ProductQuantityInput
          v-if="isEntryEditable"
          @input="onQuantityChange"
          @quantityChangedToZero="onEntryDeleteRequest"
          :value="cartEntry.quantity"
          :isButtonsVisible="false"
          :inputClassList="['form-input--shadow']"
          :minOrderQty="productData.minOrderQty"
        />
        <p class="cart-products-list__qty-text" v-else>
          <span>{{ cartEntry.quantity }}</span>
        </p>
      </div>
    </td>

    <td
      class="product-line__data product-line__data-price"
      :class="{ 'product-line__data-price--discount': cartEntry.discounts }"
      :data-th-title="'Item Price'"
    >
      <template v-if="productData.isPurchasable">
        <p
          v-if="cartData.showProductsPriceChange && cartEntry.oldPrice && (cartEntry.oldPrice !== cartEntry.adjustedPrice)"
          class="product-line__price--unavailable product-line__price--premessage"
        >
          <i class="icon-exclamation-circle" />
          <span v-html="`Price has changed (was ${formatPrice(cartEntry.oldPrice)} Each)`"></span>
        </p>
        <p class="product-line__price product-line__price--single">
          <ProductPrice
            :productData="productData"
            :adjustedPrice="cartEntry.adjustedPrice"
            class="product-price--inline"
          /> <span>Each</span>
        </p>
        <p class="product-line__price" v-if="!cartEntry.discounts">
          <span>{{ `${formatPrice(cartEntry.totalPrice)} Total` }}</span>
        </p>
        <template v-else>
          <p class="product-line__price product-line__price--discount product-price">
            <span>
              <span
                class="product-price__text product-price__text--discount"
              >{{ formatPrice(cartEntry.totalPrice) }}</span>
              <span
                class="product-price__text product-price__text--base"
              >{{ formatPrice(cartEntry.totalPriceWithoutDiscount) }}</span>
              <span
                class="product-price__text"
              >Total Discount Price</span>
            </span>
            <ul class="product-line__discounts">
              <li
                v-for="discount in cartEntry.discounts"
                :key="discount.code"
              >{{ discount.description }}</li>
            </ul>
          </p>
        </template>
      </template>
      <p v-else class="product-line__price--unavailable">
        <i class="icon-exclamation-circle" />
        <span>Product No Longer Available</span>
      </p>
    </td>

    <td
      class="product-line__data product-line__data-delete"
      :data-th-title="'Add To Cart'"
      v-if="isAddToCartVisible"
    >
      <i
        @click="onAddToCartClick"
        class="product-line__delete icon-shopping-cart"
        :class="{ 'icon--disabled': !productData.isPurchasable }"
      ></i>
    </td>

    <td
      v-if="isEntryEditable"
      :data-th-title="'Item Price'"
      class="product-line__data product-line__data-delete"
    >
      <i
        @click="onEntryDeleteRequest"
        class="product-line__delete icon-trash-alt"
      ></i>
    </td>
  </tr>

  <div class="cart-product" v-else>
    <div class="cart-product__img-wrap">
      <img
        :src="productData.image.previewSrc || '/img-placeholder.svg'"
        :alt="productData.image.title"
        class="cart-product__img"
        loading="lazy"
      />
    </div>
    <div class="cart-product__description">
      <a
        @click.prevent="handleProductLinkClick(productData.link)"
        v-html="productData.title"
        :href="productData.link"
        class="product-line__title cart-product__title"
      ></a>
      <p class="cart-product__sku">
        Catalog ID: {{ productData.catalogId }}
      </p>

      <div class="cart-product__price-wrap">
        <template v-if="productData.isPurchasable">
          <p
            v-if="cartData.showProductsPriceChange && cartEntry.oldPrice && (cartEntry.oldPrice !== cartEntry.adjustedPrice)"
            class="product-line__price--unavailable product-line__price--premessage">
            <i class="icon-exclamation-circle" />
            <span v-html="`Price has changed (was ${formatPrice(cartEntry.oldPrice)} Each)`"></span>
          </p>
          <p class="product-line__price--single cart-product__price--single">
            <ProductPrice
              :productData="productData"
              :adjustedPrice="cartEntry.adjustedPrice"
              class="product-price--inline"
            /> <span>Each</span>
          </p>
          <p class="product-line__price" v-if="!cartEntry.discounts">
            <span>{{ `${formatPrice(cartEntry.totalPrice)} Total` }}</span>
          </p>
          <template v-else>
            <p class="product-line__price product-line__price--discount product-price">
              <span>
                <span
                  class="product-price__text product-price__text--discount"
                >{{ formatPrice(cartEntry.totalPrice) }}</span>
                <span
                  class="product-price__text product-price__text--base"
                >{{ formatPrice(cartEntry.totalPriceWithoutDiscount) }}</span>
                <span
                  class="product-price__text"
                >Total Discount Price</span>
              </span>
              <ul class="product-line__discounts">
                <li
                  v-for="discount in cartEntry.discounts"
                  :key="discount.code"
                >{{ discount.description }}</li>
              </ul>
            </p>
          </template>
        </template>
        <p v-else class="product-line__price--unavailable">
          <i class="icon-exclamation-circle" />
          <span>Product No Longer Available</span>
        </p>
      </div>

      <div class="cart-product__qty-wrap">
        <div class="product-line__data-qty-text" v-if="productData.minOrderQty > 1">
          Incremental Order Quantity: {{ productData.minOrderQty }}
          <i class="icon-question-circle" v-tooltip="`This item has an incremental order quantity of ${productData.minOrderQty}; <br/> typing in a quantity value may result in rounding`" />
        </div>
        <ProductQuantityInput
          v-if="isEntryEditable"
          @input="onQuantityChange"
          @quantityChangedToZero="onEntryDeleteRequest"
          :value="cartEntry.quantity"
          :isButtonsVisible="false"
          :inputClassList="['form-input--shadow']"
          :minOrderQty="productData.minOrderQty"
        />
        <p class="cart-products-list__qty-text" v-else>
          <span>{{ `Quantity: ${cartEntry.quantity}` }}</span>
        </p>
      </div>

      <div class="cart-product__actions">
        <slot name="actions"></slot>
      </div>
    </div>

    <div class="cart-product__delete-wrap" v-if="isAddToCartVisible">
      <i
        @click="onAddToCartClick"
        class="product-line__delete icon-shopping-cart"
        :class="{ 'icon--disabled': !productData.isPurchasable }"
      ></i>
    </div>

    <div class="cart-product__delete-wrap" v-if="isEntryEditable">
      <i
        @click="onEntryDeleteRequest"
        class="product-line__delete icon-trash-alt"
      ></i>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import FormatMixin from '@/mixins/FormatMixin'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import NavigationalMixin from '@/mixins/NavigationalMixin'
import ProductPrice from '@/components/ProductPrice'
import ProductQuantityInput from '@/components/ProductQuantityInput'

export default {
  name: 'CartProduct',
  components: {
    ProductPrice,
    ProductQuantityInput
  },
  mixins: [FormatMixin, ScreenWidthMixin, NavigationalMixin],
  props: {
    cartEntry: {
      type: Object,
      required: true
    },
    isEntryEditable: {
      type: Boolean,
      default: true
    },
    isAddToCartVisible: {
      type: Boolean,
      default: true
    },
    isImageClickable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('cart', ['cartData']),
    productData () {
      return this.cartEntry.product
    }
  },
  methods: {
    onAddToCartClick () {
      if (this.productData.isPurchasable) {
        this.$emit('addToCartRequested', { entryNumber: this.cartEntry.number, title: this.productData.title })
      }
    },
    onQuantityChange (newQuantity) {
      this.$emit('quantityUpdated', {
        cartEntry: this.cartEntry,
        productData: this.productData,
        entryNumber: this.cartEntry.number,
        qty: newQuantity,
        oldQty: this.cartEntry.quantity
      })
    },
    handleProductLinkClick (link) {
      if (this.productData.isPurchasable) {
        this.$emit('navigateToProductDetails')
        this.navigateTo(link)
      }
    },
    onEntryDeleteRequest () {
      this.$emit('entryDeleteRequested', {
        cartEntry: this.cartEntry,
        productData: this.productData,
        entryNumber: this.cartEntry.number,
        title: this.productData.title,
        quantity: this.cartEntry.quantity,
        price: this.cartEntry.adjustedPrice,
        sku: this.productData.sku,
        catalogId: this.productData.catalogId
      })
    }
  }
}
</script>
