<template>
  <Modal
    @close="$emit('close')"
    :showModal="showModal"
    :isLoading="isLoading"
    class="modal-address-validation"
  >
    <template v-slot:header>
      <div class="modal-address-validation__header">
        Address Validation
      </div>
    </template>
    <template v-slot:body>
      <p class="modal-address-validation__sub-title">
        We were not able to validate the address exactly as entered, but found a close match
      </p>
      <div class="modal-address-validation__hint-wrapper">
        <div class="modal-address-validation__hint-container">
          <h3 class="text-h5 modal-address-validation__address-title">Entered</h3>
          <div>
            <AddressItem :data="enteredData" />
          </div>
        </div>
        <div class="modal-address-validation__hint-container">
          <h3 class="text-h5 modal-address-validation__address-title">Suggested</h3>
          <div>
            <AddressItem :data="suggestedData" />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modal__actions">
        <button
          @click="$emit('close')"
          type="button"
          class="button button--outlined"
        >
          Cancel
        </button>
        <button
          @click="$emit('confirm', suggestedData)"
          type="button"
          class="button button--primary"
        >
          Update Address
        </button>
      </div>
      <div class="modal-address-validation__bottom-copy-wrap">
        <p>
          In order to ensure your shipment can be delivered, Hubbell requires a validated address;
          please <a class="link" href="/customer-support" target="_blank">Contact Us</a> if any address validation issues
        </p>
      </div>
    </template>
  </Modal>
</template>

<script>
import AddressItem from '@/components/AddressItem'
import Modal from '@/components/modal/Modal'

export default {
  name: 'ModalAddressValidation',
  components: {
    AddressItem,
    Modal
  },
  props: ['showModal', 'enteredData', 'suggestedData', 'isLoading']
}
</script>
