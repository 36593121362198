<template>
  <div
    class="form-group"
    :class="{
      'form-group--inline': model.inline,
      'form-group--shadow': model.variant === 'shadow',
      'form-group--error': isValidationRun && field.value && field.value.$invalid,
      'form-group--disabled': disabled || model.disabled,
    }"
  >
    <span class="form-group__label"
      v-if="model.placeholder"
      v-html="model.placeholder"
    ></span>

    <div class="select__container">
      <select
        v-model="selectedOption"
        :disabled="disabled || model.disabled"
        class="select"
        :class="{
          'select--shadow': model.variant === 'shadow',
          'select--error': isValidationRun && field.value && field.value.$invalid,
          'select--disabled': disabled || model.disabled
        }"
      >
        <option
          v-for="(option, idx) in model.options"
          :key="idx"
          :value="option"
        >{{ option[model.titleField] || option }}</option>
      </select>
    </div>
    <template v-if="isValidationRun && errors">
      <span
        v-for="(error, idx) in errors"
        :key="idx"
        v-html="error"
        class="form-group__hint"
      >
      </span>
    </template>

  </div>
</template>

<script>
import FormControlMixin from '@/mixins/FormControlMixin'

export default {
  mixins: [FormControlMixin],
  computed: {
    selectedOption: {
      get () {
        return this.value
      },
      set (newSelectedOption) {
        this.$emit('input', newSelectedOption)
      }
    }
  }
}
</script>
