var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group",class:{
    'form-group--inline': _vm.model.inline,
    'form-group--shadow': _vm.model.variant === 'shadow',
    'form-group--error': _vm.isValidationRun && _vm.field.value && _vm.field.value.$invalid,
    'form-group--disabled': _vm.disabled || _vm.model.disabled,
  }},[(_vm.model.placeholder)?_c('span',{staticClass:"form-group__label",domProps:{"innerHTML":_vm._s(_vm.model.placeholder)}}):_vm._e(),_c('div',{staticClass:"select__container"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedOption),expression:"selectedOption"}],staticClass:"select",class:{
        'select--shadow': _vm.model.variant === 'shadow',
        'select--error': _vm.isValidationRun && _vm.field.value && _vm.field.value.$invalid,
        'select--disabled': _vm.disabled || _vm.model.disabled
      },attrs:{"disabled":_vm.disabled || _vm.model.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedOption=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.model.options),function(option,idx){return _c('option',{key:idx,domProps:{"value":option}},[_vm._v(_vm._s(option[_vm.model.titleField] || option))])}),0)]),(_vm.isValidationRun && _vm.errors)?_vm._l((_vm.errors),function(error,idx){return _c('span',{key:idx,staticClass:"form-group__hint",domProps:{"innerHTML":_vm._s(error)}})}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }