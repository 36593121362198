<template>
  <Modal
    :showModal="showModal"
    :isLoading="isLoading"
    @close="$emit('close')"
    class="modal-simple"
  >
    <template v-slot:header>
      <h3 class="text-h3" v-html="title"></h3>
    </template>
    <template v-slot:footer>
      <div class="modal__actions">
        <button
          v-if="showRejectButton"
          type="button"
          class="button button--outlined"
          @click="$emit('reject')"
        >{{ rejectButtonText }}</button>
        <button
          v-if="showConfirmButton"
          type="button"
          class="button button--primary"
          @click="$emit('confirm')"
        >{{ confirmButtonText }}</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal'

export default {
  name: 'ModalSimple',
  components: {
    Modal
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    showRejectButton: {
      type: Boolean,
      default: true
    },
    showConfirmButton: {
      type: Boolean,
      default: true
    },
    rejectButtonText: {
      type: String,
      default: 'Cancel'
    },
    confirmButtonText: {
      type: String,
      default: 'Remove'
    }
  }
}
</script>
